
import {defineComponent, ref, computed} from "vue";
import topNav from "@/components/home/topNav.vue";
import bottomNav from "@/components/home/bottomNav.vue";
import ExtraInfoPage from "./ExtraInfoPage.vue"
import {useLoading} from "vue-loading-overlay";
import User from "../../models/user";
import * as toastr from "toastr";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {key} from "@/store/index";
import FuncUtils from "@/utils/funcUtils";
import {validatePassword} from "@/utils/util";
import {useI18n} from 'vue-i18n'

export default defineComponent({
  name: 'SignUp',
  components: {
    topNav,
    bottomNav,
    'extra-info-page': ExtraInfoPage
  },
  setup() {
    const registerRef = ref(null);
    const user = ref<User>(new User());
    const password2 = ref("");
    const store = useStore(key);
    const $loading: any = useLoading();
    store.dispatch('country/fetch')
    const {t} = useI18n()
    const router = useRouter();
    const fieldsToExclude = ['id', 'deviceTokens', 'userType', 'ipAddress', 'createdAt', 'updatedAt'];
    let extraStep = ref(false);

    const signUpWithEmailAndPassword = async () => {

      if (!FuncUtils.validateFields(user.value, fieldsToExclude)) {
        console.log(user.value)
        return toastr.warning(t('signUpPage.validationMessage'))
      } else if (!validatePassword(user.value.password)) {
        return toastr.warning(t('signUpPage.invalidPassword'))
      } else if (user.value.password !== password2.value) {
        return toastr.warning(t('signUpPage.passwordMismatch'))
      }
      /*else if (user.value.password.length < 8) {
        return toastr.warning("password must be at least 8 character length")
      }*/

      const loading = $loading.show({container: registerRef.value});
      try {
        const response = await store.dispatch('auth/signUpWithEmailAndPassword', {user: user.value});
        if (response.status) {
          await router.push({name: 'Home'})
          await toastr.success(t('signUpPage.signUpMessage'));
        }
      } catch (e) {
        await toastr.error(e.message);
      } finally {
        loading.hide();
      }

    }

    const signInWithGoogle = async () => {
      const loading = $loading.show({container: registerRef.value});
      try {
        const response = await store.dispatch('auth/signInWithGoogle');
        if (response.isNewUser) {
          user.value = response.user;
          extraStep.value = true;
          return;
        } else if (response.status) {
          await toastr.success(response.message);
          return router.push({name: 'Home'})
        }
      } catch (e) {
        await toastr.error(e.message)
      } finally {
        loading.hide();
      }
    }

    const signInWithApple = async () => {

      const loading = $loading.show({container: registerRef.value});
      try {
        const response = await store.dispatch('auth/signInWithApple');
        if (response.isNewUser) {
          user.value = response.user;
          extraStep.value = true;
          return;
        } else if (response.status) {
          await toastr.success(response.message);
          return  router.push({name: 'Home'})
        }
      }catch (e) {
        await toastr.error(e.message);
      }finally {
        loading.hide();
      }

    }

    const completeExtraStep = async (context) => {
      user.value = context
      if (!FuncUtils.validateFields(user.value, [...fieldsToExclude, 'password'])) {
        return toastr.warning(t('signUpPage.validationMessage'))
      }

      const loading = $loading.show({container: registerRef.value});

      try {
        const response = await store.dispatch('auth/completeExtraStep', {user: user.value});

        if (response.status === 200) {
          await toastr.success(t('loginPage.loginMessage'));
          await router.push({name: 'Home'})
        }
      } catch (e) {
        await toastr.error(e.message);
      } finally {
        loading.hide();
      }
    }

    const countries = computed(() => store.getters['country/getCountries'])

    return {
      countries,
      user,
      password2,
      registerRef,
      extraStep,
      signUpWithEmailAndPassword,
      signInWithGoogle,
      signInWithApple,
      completeExtraStep,
    }
  },
})
