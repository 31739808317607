
import {defineComponent, reactive, toRefs, toRef} from "vue";

export default defineComponent({
  name: 'GoogleExtraPage',
  props: ['user', 'countries'],
  emits: ['extraSetup'],
  setup(props) {
    const state = reactive({
      user_: toRef(props, 'user')
    })

    return {...toRefs(state)}
  },

});

