import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "login--form" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { for: "firstname" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { for: "lastName" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { for: "phone" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { for: "user_country1" }
const _hoisted_12 = { value: "" }
const _hoisted_13 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('signUpPage.firstName')), 1),
      _withDirectives(_createElementVNode("input", {
        id: "firstname",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user_.firstName) = $event)),
        type: "text",
        class: "text_field",
        placeholder: _ctx.$t('signUpPage.firstNameHint'),
        required: ""
      }, null, 8, _hoisted_4), [
        [_vModelText, _ctx.user_.firstName]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('signUpPage.lastName')), 1),
      _withDirectives(_createElementVNode("input", {
        id: "lastName",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user_.lastName) = $event)),
        type: "text",
        class: "text_field",
        placeholder: _ctx.$t('signUpPage.lastNameHint'),
        required: ""
      }, null, 8, _hoisted_7), [
        [_vModelText, _ctx.user_.lastName]
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('signUpPage.phone')), 1),
      _withDirectives(_createElementVNode("input", {
        id: "phone",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user_.phoneNumber) = $event)),
        type: "tel",
        class: "text_field",
        placeholder: "000-000-000-000",
        required: ""
      }, null, 512), [
        [_vModelText, _ctx.user_.phoneNumber]
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('signUpPage.country')), 1),
      _withDirectives(_createElementVNode("select", {
        id: "user_country1",
        class: "select--field",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user_.countryID) = $event))
      }, [
        _createElementVNode("option", _hoisted_12, _toDisplayString(_ctx.$t('signUpPage.selectCountryHint')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country, idx) => {
          return (_openBlock(), _createElementBlock("option", {
            value: country.id,
            key: 'country_'+idx
          }, _toDisplayString(country.name), 9, _hoisted_13))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.user_.countryID]
      ])
    ]),
    _createElementVNode("button", {
      onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$emit('extraSetup', _ctx.user_)), ["prevent"])),
      class: "btn btn--md register_btn btn-outline-primary",
      type: "button"
    }, _toDisplayString(_ctx.$t('signUpPage.proceedButtonText')), 1)
  ]))
}